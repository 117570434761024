import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.less']
})


export class MapaComponent implements OnInit {
  title = 'My first AGM project';
  lat = 49.751773806806996;
  lng = 18.628495686064527;
  constructor() { }

  ngOnInit(): void {
  }


}

