import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {HttpService} from "../http.service";
import  packets from "../data/pakiety.json"
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-formularz',
  templateUrl: './formularz.component.html',
  styleUrls: ['./formularz.component.less']
})


export class FormularzComponent implements OnInit {
  public miasto;

  private id;
  public pakiety: { id:number,name:string,opis:string,cena:number,image:string }[]=packets
  

  submitted = false;
  waznosc = ["1 rok" ,"2 lata", "3 lata"]
  model={imie:'',tel:'',email:'',rodzaj:'',okres:'',przedluzenie:'NIE',tozsamosc:'',pakiet:''}
  constructor(private route: ActivatedRoute,private http:HttpClient,private router:Router ) {
    this.model.rodzaj='0';
    this.model.okres='1 rok';
    this.model.tozsamosc='NIE WIEM';
    this.model.pakiet='Certum Mini';

  }


  submit(formularz){
    this.model.pakiet=this.pakiety[this.model.rodzaj].name;
    this.submitted = true;
    this.http.post<any>('https://w-beskidach.pl/mail/mail.php',{token:'123',data:this.model}).subscribe(data => {
   
    })
    this.router.navigate(['thanx']);
  }
  ngOnInit(): void {
    
    this.route.params.subscribe((params: Params) => {
      let id = params['id'];  
      this.model.rodzaj=id
     
    })
  }

 

}
