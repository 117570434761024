import { Component, OnInit } from '@angular/core';
import  packets from "../data/pakiety.json"
import {Meta, Title} from "@angular/platform-browser";


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.less']
})
export class HomepageComponent implements OnInit {
  public pakiety: { name:string,opis:string,cena:number,image:string }[]=packets

  constructor(private titleService: Title,private metaService: Meta) {

  }


  ngOnInit(): void {
    this.titleService.setTitle('Podpis elektroniczny / Certyfikat kwalifikowany / Certum / Podpis elektroniczny  Cieszyn i okolice ')
    this.metaService.addTags([
      {name:'description',content:'Podpis elektroniczny inaczej - Certyfikat kwalifikowany jest kluczowym elementem kontaktu z administracją (m.in. ZUS/US ). Z nami uzyskasz nowy podpis elektroniczny lub odnowisz stary.'},
      {name:'keywords',content:'Podpis elektroniczny, Podpis kwalifikowny, Certum, Odnowienie podpisu, Potwierdzenie tożsamości '}
    ]);
  }

}
