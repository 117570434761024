<app-header></app-header>
<div class="container">
  <div class="row">
    <div class="xs-12 col-md-12 text-center"><h3 class="text-center">Podpis elektroniczny w  {{miasto?.wkimwczym | titlecase}}</h3>
    </div>
  </div>
  <div class="row">
    <div class="xs-12 col-md-8"  >
      <br>
      Prowadzisz działalność w {{ miasto?.wkimwczym | titlecase }}? Potrzebujesz bezproblemo komunikować się z administracją
      publiczną? Masz potrzebę zawierania umów B2B na odległość (istotna rzecz w erze Covida) ?. A może startujesz w
      przetargach ?
      <br/>
      Wprowadź firmę w XXI wiek i wyprzedź konkurencję w mieście {{miasto?.nazwa | titlecase}} - zacznij korzystać z
      podpisu elektronicznego z certyfikatem kwalifikowanym - najpopularniejszym i najbardziej funkcjonalnym podpisem elektronicznym w Polsce.
      <br/><br/>
    </div>
    <div class="xs-12 col-md-4   img">
      <img src="assets/certum-mini.png" alt="Podpis elektroniczny {{ miasto?.nazwa }}">
    </div>
  </div>
  <div class="row">
    <div class="xs-12 col-md-12 ">
      <h5>Podpis elektroniczy z certyfikatem kwalifikowanym - {{ miasto?.nazwa| titlecase}} w 15 minut. </h5>
      Tak - jesteśmy w stanie przeprowadzić procedurę złożenia wniosku w 15 minut w godzinach roboczych. Wystarczy, iż
      zgłosisz się do nas z aktualnym dowodem osobistym.
      <br/><br/>

      <h5>Rozszerzony podpis elektroniczy - {{ miasto?.nazwa| titlecase}} </h5>
      Twój podpis elektroniczny może poza imieniem i nazwiskiem dodatkwo zawierać zarówno Twoje stanowisko jak i nazwę
      firmy którą reprezentujesz. Wystarczy, że przyjdziesz do nasz z odpowiednimi dokumentami. To nic więcej nie
      kosztuje.
      <br/><br/>
      <h5>Szerokie zastosowanie</h5>
      Kwalifikowany podpis elektroniczy znajduje bardzo duże zastosowanie w dzisiejszym świecie. Prowadź swoją
      działalność z domu, bez wyjeżdżania z {{miasto?.kogoczego | titlecase}}. <br><br>

      <h5>Gwarancja uznania</h5>
      Podpis posłuży Ci do cyfrowego podpisywania dokumentów. Ma on dokładnie takie same skutki prawne co tradycyjny,
      odręczny podpis. A przy tym nie ma potrzeby druku ani skanowania podpisanego dokumentu- oszczędzisz zarówno czas
      jak i pieniądze- wszystko to zrobisz z {{ miasto?.kogoczego | titlecase }}.
      <br><br>
      Stosowanie podpisu elektronicznego w dowolnej formie zwiększa bezpieczeństwo ponieważ podpis jest niemożliwy do
      podrobienia.
      <br><br>
    </div>

  </div>
  <app-dlaczego-my></app-dlaczego-my>
  <div class="row">
    <div class="xs-12 col-md-12">
      <app-miasta></app-miasta>
    </div>
  </div>
  <app-inneuslugi></app-inneuslugi>
</div>
<app-footer></app-footer>
