import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pakiety',
  templateUrl: './pakiety.component.html',
  styleUrls: ['./pakiety.component.less']
})
export class PakietyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
