import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Miasto} from "./model/miasto";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http:HttpClient) { }

  getMiasto(id :number):Observable<Miasto>{
    return this.http.get<Miasto>('https://kasyfiskalne.cieszyn.pl/api/miasto/'+id);
  }

  getMiasta(): Observable<Array<Miasto>>{
    return this.http.get<Array<Miasto>>('https://kasyfiskalne.cieszyn.pl/api/miasta');
  }
}
