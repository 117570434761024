<footer class="container-fluid">
  <div class="container">
    <div class="row">
      <div class="xs12 col-md-4 text-center text-md-left">
        <strong>Sklep komputerowy komputery.cieszyn.pl</strong><br/>
        43-400 Cieszyn<br/>
        ul.Zamkowa 24<br/>
        <br/>
        <a href="tel:537055300">tel.537 055 300</a><br/>
        <a href="mailto:sklep@getcomp.pl">sklep@getcomp.pl</a><br/>


      </div>
      <div class="xs12 col-md-4 text-center">
        Certyfikat kwalifikowany<br/>
        Podpis elektroniczny<br/>
        Odnowienie podpisu<br/>
        Potwierdzenie tożsamości<br/>
        <br/>
        <br/>
      </div>
      <div class="xs12 col-md-4 text-center text-md-right">
        <a href="https://komputery.cieszyn.pl/">Komputery</a><br/>
        <a href="https://komputery.cieszyn.pl/#serwis">Serwis komputerowy</a><br/>
        <a href="https://kasyfiskalne.cieszyn.pl/">Kasy Fiskalne Online</a><br/>
        <a href="https://pomoc.certum.pl/pl/oprogramowanie-biblioteki-podpis-elektroniczny/">Oprogramowanie do podpisu</a><br/>
        <a href="/faq">FAQ</a><br/>

      </div>
    </div>
  </div>

  <div class="mapa"></div>
  <div class="xs-12 text-center copyright">
    Wszystkie prawa zastrzeżone &copy; komputery.cieszyn.pl<br/>
    Projekt i wykonanie <a href="https://pozycjoner.net">pozycjoner.net</a>
  </div>
</footer>
