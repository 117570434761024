import { Component, OnInit } from '@angular/core';
import data from "../data/uslugi.json"

@Component({
  selector: 'app-uslugi',
  templateUrl: './uslugi.component.html',
  styleUrls: ['./uslugi.component.less']
})
export class UslugiComponent implements OnInit {
  public uslugi: { name:string,opis:string }[]=data
  constructor() { }

  ngOnInit(): void {
  }

}
