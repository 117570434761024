<app-header></app-header>
<div class="container">
<div class="row">
    <div class="col-12 mb-4">
        <h2>FAQ </h2>
        <ul>
            <li><b>Jak załatwić podpis elektroniczny ? </b> Wejdź na <a href="/Zamowienie/0">stronę formularza</a> i wypełnij formularz, a my się resztą zajmniemy</li>
            <li><b>Jak podpisać dokument podpisem elektronicznym</b>
                Przykład składania podpisu można zobaczyć na kanale YT Certum <a href="https://www.youtube.com/watch?v=lmxmQv_ghvw">https://www.youtube.com/watch?v=lmxmQv_ghvw</a>
            </li>
            <li><b>Jak podpisać email podpisem kwalifikowanym ?</b> Podpis kwalifikowany nie służy do podpisywania e-maili.Podpisen ten podpisuje pliki (np załączniki) </li>
            <li><b>Ile trwa wyrobienie podpisu kwalifikowanego ?</b> W trybie przyśpieszonym  do 24h </li>
            <li><b>Ile kosztuje wyrobienie podpisu kwalifikowanego ?</b>Na cenę składa się zestaw do podpisu oraz potwierdzenie tożsamości (cena zależna od szybkości potwierdzenia) </li>
 
        </ul>        
    </div>
</div>
 
</div>
 



<app-footer></app-footer>
<app-mapa></app-mapa>
