<app-header></app-header>
<div class="container">
  <section class="whitesection" id="wstep">
    <div class="row d-block d-sm-block d-md-none">
      <div class="xs-12 col-md-8">
        <br/><br/>
        <br/><br/>
      </div>
    </div>
    <div class="row">
      <div class="xs-12 col-md-8">
        Kontakt z Urzędami nawet bez pandemii jest trudny. I to jest fakt. Jeśli prowadzisz biznes to praktycznie
        wcześniej lub później będziesz zmuszany do użycia podpisu elektronicznego.
        Owszem, są wynalazki takie jak profil zaufany czy certyfikat osobisty (nowe dowody), ale wynalazki te mają
        ograniczenia o których się nie mówi - czy to <span class="kursor"
                                                           ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">brak możliwości wykorzystania poza kontaktem z administracją</span>
        , czy to <span class="kursor" ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">nierealność wykorzystania w postępowaniu przetargowym</span>.
        Jeśli chcesz mieć pewność, iż Twój podpis elektroniczny będzie zawsze i wszędzie rozpoznawany, weryfikowany i
        uznawany zaopatrz się w certyfikat kwalifikowany.

</div>
      <div class="xs-12 col-md-4 partner text-center">
        <span class="d-block d-sm-block d-md-none" style="margin-top:30px;"></span>
          <img src="assets/partner.png" alt="Autoryzowany Partner Certum" class=" d-sm-block d-md-none" style="max-width: 50%">
          <img src="assets/partner.png" alt="Autoryzowany Partner Certum" class="d-none d-sm-none d-md-block">

      </div>
    </div>

  </section>
</div>


<section class="redsection">
  <div class="container">
    <app-uslugi></app-uslugi>
  </div>


</section>

<section class="container">
  <div class="container">

    <ngb-carousel [showNavigationArrows]="false" [showNavigationIndicators]="false">
      <ng-template ngbSlide *ngFor="let pakiet of pakiety">
        <div class="row homepagebox">
          <div class="xs-12 col-md-6"><img src="{{pakiet.image}}" alt="Podpis elektropniczny {{pakiet.image}}"></div>
          <div class="xs-12 col-md-6">
            <h3>{{pakiet.name}}</h3>
            <div [innerHTML]="pakiet.opis"></div>

          </div>
        </div>
        <div class="xs-12 text-right">
          Cena od <strong>{{(pakiet.cena*1.23) | currency }}</strong> 
        </div>
 
          <div class="col-12 text-center justify-content-center">
            <a href="/Zamowienie/{{pakiet.id}}" class="btn btn-success">Zamawiam</a>
          </div>
  
      </ng-template>
    </ngb-carousel>

  </div>
</section>
<hr>


<app-dlaczego-my></app-dlaczego-my>
<app-miasta></app-miasta>
<app-inneuslugi></app-inneuslugi>
<app-footer></app-footer>
<app-mapa></app-mapa>
