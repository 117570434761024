import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomepageComponent} from "./homepage/homepage.component"; HomepageComponent
import {PakietComponent} from "./pakiet/pakiet.component";
import {MiastoComponent} from "./miasto/miasto.component";
import { FaqComponent } from './faq/faq.component';
import { FormularzComponent } from './formularz/formularz.component';
import { ThanksComponent } from './thanks/thanks.component';

const routes: Routes = [
  { path: '', redirectTo: '/PodpisKwalifikowany', pathMatch: 'full' },
  { path: 'PodpisKwalifikowany', component: HomepageComponent },
  { path: 'Pakiet', component: PakietComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'thanx', component: ThanksComponent },
  { path: 'PodpisKwalifikowany/:miasto/:id', component: MiastoComponent, pathMatch: 'full' },
  { path: 'Zamowienie/:id', component: FormularzComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
