<section id=" redsection" class="dlaczegomy">
  <div class="container">
    <div class="row">
      <div class="xs-12 col-md-12 text-center">
        <h2 class="text-center">Dlaczego warto u nas zamówić podpis elektroniczny?</h2></div>
    </div>
    <div class="row">
      <div class="xs-12 col-md-4 text-center">
        <img src="assets/box/expirience.png" alt="Firma z doświadczeniem"><br/>
        <strong> Lata doświadczeń</strong><br/>
        <span>Jako firma działająca na rynku IT istniejemy od 2007 roku </span></div>
      <div class="xs-12 col-md-4 text-center">
        <img src="assets/box/support.png" alt="Pomoc z podpisem"><br/>
        <strong> Zdalna Pomoc</strong><br/>
        Dzięki programowi do pomocy zdalej jesteśmy w stanie pomóc Ci zdalnie z instalacją oprogramowania, bądz wgraniem
        podpisu na kartę
      </div>
      <div class="xs-12 col-md-4 text-center">
        <img src="assets/box/elastic.png" alt="Jesteśmy elastyczną firmą"><br/>
        <strong> Elastyczność</strong><br/>
        Płatność przelewem? Jedzieś z daleka i potrzebujesz aby na Ciebie zaczekać? Trzeba podjechać i potwierdzić
        tożsamość? Jesteśmy elastyczni i wszystko z nami jest możliwe
      </div>

    </div>
  </div>
</section>

