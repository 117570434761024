<app-header></app-header>
<div class="container">
<div class="row">
    <div class="col-12 mb-4 text-center justify-content-center">
        <h2>Dziękujemy za wypełnienie formularza</h2>
        Skontakujemy się z Tobą w celu ustalenia szczegółów .
    </div>
</div>
</div>




<app-inneuslugi></app-inneuslugi>
<app-miasta></app-miasta>
<app-footer></app-footer>
<app-mapa></app-mapa>
