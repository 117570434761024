import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Miasto} from "../model/miasto";
import {HttpService} from "../http.service";


@Component({
  selector: 'app-miasta',
  templateUrl: './miasta.component.html',
  styleUrls: ['./miasta.component.less']
})
export class MiastaComponent implements OnInit {
  miasta;
  constructor(private miastaService:HttpService) { }

  ngOnInit(): void {
    this.miastaService.getMiasta().subscribe((res)=>{
      this.miasta=res;
    })

  }

}
