import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
 


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  constructor( ) { }

  ngOnInit(): void {
    
  }

  
}
