<header>
  <div class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="xs-12 col-md-6 text-center text-md-left logo">
        <span class="  d-sm-block d-md-none"><a href="/">
          <img src="/assets/logo.png"
               alt="Podpis Certum Cieszyn"><br/><strong>Podpis Elektroniczny Cieszyn</strong></a></span>
          <span class="vertical-center d-none d-sm-none d-md-block"><a href="/">
          <img src="/assets/logo.png"
               alt="Podpis Certum Cieszyn"><strong>Podpis Elektroniczny Cieszyn</strong></a></span>
        </div>
        <div class="xs-12 col-md-6 text-center text-md-right adres">43-400 Cieszyn<br/>Zamkowa 24<br/>
          tel. 537 055 300</div>
      </div>
    </div>
  </div>
</header>
