import { Component, OnInit } from '@angular/core';
import data from "../data/inneuslugi.json";

@Component({
  selector: 'app-inneuslugi',
  templateUrl: './inneuslugi.component.html',
  styleUrls: ['./inneuslugi.component.less']
})
export class InneuslugiComponent implements OnInit {
  public uslugi: { name:string,img:string,alt:string,url:string }[]=data
  constructor() { }

  ngOnInit(): void {
  }

}
