
<div class="container">
  <div class="row" style="margin-bottom:10px;">
    <div class="xs-12 d-sm-block d-md-none  text-center">
      <div class="text-center" style="text-align: center;">Nasze pozostałe usługi:</div>
    </div>
    <div class="xs-12 d-none d-sm-none d-md-block">
      Nasze pozostałe usługi:
    </div>
  </div>
  <div class="row uslugi">
    <div class="xs-12 col-md-4"  *ngFor="let usluga of uslugi">
      <a href="{{usluga.url}}" title={{usluga.alt}}><img src="{{ usluga.img }}" alt="{{usluga.alt}}">{{usluga.name}}</a>
    </div>
  </div>

</div>
