import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Miasto} from "../model/miasto";
import {ActivatedRoute, Params} from "@angular/router";
import {HttpService} from "../http.service";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-miasto',
  templateUrl: './miasto.component.html',
  styleUrls: ['./miasto.component.less']
})
export class MiastoComponent implements OnInit {
  public miasto;
  private id;

  constructor(private titleService: Title,private metaService: Meta,private miastoService: HttpService, private route: ActivatedRoute) {

  }

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      let id = params['id'];
      this.refresh(id);
    })
  }

  refresh(id) {
    this.miastoService.getMiasto(id).subscribe(data => {
      this.miasto = data;
      this.titleService.setTitle('Podpis elektroniczny - '+this.miasto.nazwa.toLocaleUpperCase()+ ', Podpis kwalifikowny '+this.miasto.nazwa.toLocaleUpperCase()+', Certum '+this.miasto.nazwa.toLocaleUpperCase())
      this.metaService.addTags([
        {name:'description',content:'Podpis elektroniczny inaczej - Certyfikat kwalifikowany jest kluczowym elementem kontaktu z administracją (m.in. ZUS/US ). Z nami uzyskasz nowy podpis elektroniczny lub odnowisz stary.'},
        {name:'keywords',content:'Podpis elektroniczny - '+this.miasto.nazwa+ ', Podpis kwalifikowny '+this.miasto.nazwa+', Certum '+this.miasto.nazwa + ' Odnowienie podpisu, Potwierdzenie tożsamości '}
        ]);

    });
  }


}
