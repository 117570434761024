import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomepageComponent } from './homepage/homepage.component';
import { PakietComponent } from './pakiet/pakiet.component';
import { PakietyComponent } from './pakiety/pakiety.component';
import { UslugiComponent } from './uslugi/uslugi.component';
import { MiastaComponent } from './miasta/miasta.component';
import { HttpClientModule } from "@angular/common/http";
import { MiastoComponent } from './miasto/miasto.component';
import { MapaComponent } from './mapa/mapa.component';
import { AgmCoreModule } from '@agm/core';
import { InneuslugiComponent } from './inneuslugi/inneuslugi.component';import localePl from '@angular/common/locales/pl';
import { registerLocaleData } from '@angular/common';
import { DlaczegoMyComponent } from './dlaczego-my/dlaczego-my.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { FormularzComponent } from './formularz/formularz.component';
import { FormsModule } from '@angular/forms';
import { FaqComponent } from './faq/faq.component';
import { ThanksComponent } from './thanks/thanks.component';


registerLocaleData(localePl, 'pl-PL');

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    PakietComponent,
    PakietyComponent,
    UslugiComponent,
    MiastaComponent,
    MiastoComponent,
    MapaComponent,
    InneuslugiComponent,
    DlaczegoMyComponent,
    FooterComponent,
    HeaderComponent,
    FormularzComponent,
    FaqComponent,
    ThanksComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDtqOd3gSkW4KBwhsXHSsgiwewX1See6OM'
    }),
    FormsModule

  ],
  providers: [{
    provide: LOCALE_ID,
    useValue: 'pl-PL' // 'de-DE' for Germany, 'fr-FR' for France ...
  },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'zł'
    },
    {provide: 'googleTagManagerId', useValue: 'GTM-NPKGRFM'}
  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
