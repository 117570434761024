<app-header></app-header>
<div class="container">
<div class="row">
    <div class="col-12 mb-4">
        <h2>Formularz Zamówienia</h2>
        Jeśli chcesz uzyskać podpis wypełnij poniższy formularz, a My niezwłocznie z Tobą się skontaktujemy i ustalimy szczegóły. Ze względu na złożoność procesu uzyskania podpisu, wysłanie formularza nie jest zawarciem umowy kupna (w rozumieni KC), a tylko wyrażeniem takiej chęci.
    </div>
</div>
<div class="row">
    <div class="col-xs-12 col-6">
        <div class="xs-12 "><img src="{{pakiety[model.rodzaj].image}}" alt="Podpis elektropniczny {{pakiety[model.rodzaj].image}}" style="width:90%;height:auto"></div>
        <div class="xs-12  ">
          <h3>{{pakiety[model.rodzaj].name}}</h3>
         </div>
         <div class="xs-12">
            <strong>{{(pakiety[model.rodzaj].ceny[model.okres]) | currency }}</strong> - bez potwierdzenia tożsamości
         </div>
 
        
    </div>
  
    <div class="col-xs-12 col-6"> 
        <div *ngIf="formularz.submitted">
            <h4>Dziękujemy za przesłanie zapytania</h4>

        </div>
        <form #formularz="ngForm"   (ngSubmit)="submit(formularz)" [hidden]="submitted" method="post"  >
            <h5>Rodzaj Podpisu</h5>
            <div class="form-group">
                <label for="rodzaj">Rodzaj Podpisu</label><br/>
                <select #rodzaj="ngModel"   name="rodzaj" class="form-control" required [(ngModel)]="model.rodzaj">
                    <option value="{{ pakiet.id}}" *ngFor="let pakiet of pakiety">{{ pakiet.name}}</option>
                </select>
                <input type="hidden" value="{{ pakiety[model.rodzaj].name }}" name="pakiet" [(ngModel)]="model.pakiet">
               
            </div>
            <div class="form-group">
                <label for="okres">Okres ważności</label><br/>
                <select #rodzaj="ngModel"  name="okres" class="form-control" required [(ngModel)]="model.okres">
                  <option value="{{rok}}" *ngFor="let rok of waznosc" >{{rok}}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="przedluzenie">Przedłużenie</label><br/>
                <select #przedluzenie="ngModel" name="przedluzenie" class="form-control" required [(ngModel)]="model.przedluzenie">
                    <option value="NIE" selected>NIE</option>
                    <option value="TAK">TAK</option>    
                </select>
               
            </div>
            <div class="form-group">
                <label for="przedluzenie">Czy wymagane jest potwierdzenie tożsamości ?</label><br/>
                <select #rozsamosc="ngModel" name="tozsamosc" class="form-control" required [(ngModel)]="model.tozsamosc">
                    <option value="NIE WIEM" selected>NIE WIEM</option>
                    <option value="NIE">NIE</option>    
                    <option value="TAK">TAK</option>    
                </select>
               
            </div>
            <h5>Dane Kontaktowe</h5>
            <div class="form-group">
                <label for="imie">Imię i Nazwisko</label><br/>
                <input #name="ngModel" type="text" name="imie" class="form-control" required [(ngModel)]="model.imie">
                <div *ngIf="name.touched && name.invalid" class="alert alert-danger" >
                    <p *ngIf="name.errors.required">Pole wymagane</p>
                </div>
            </div>
            <div class="form-group">
                <label for="tel">Tel</label><br/>
                <input #tel="ngModel" type="text" name="tel" class="form-control" [(ngModel)]="model.tel">
                <div *ngIf="tel.touched && tel.invalid" class="alert alert-danger" >
                    <p *ngIf="tel.errors.required">Pole wymagane</p>
                </div>
            </div>
            <div class="form-group">
                <label for="email">Email</label><br/>
                <input #email="ngModel" type="email" name="email" class="form-control" required [(ngModel)]="model.email">
                <div *ngIf="email.touched && email.invalid" class="alert alert-danger" >
                    <p *ngIf="email.errors.email">Niepoprawny adres e-mail</p>
                    <p *ngIf="email.errors.required">Pole wymagane</p>
                </div>
            </div>

            <button class="btn btn-primary" type="submit" [disabled]="!formularz.form.valid" >Wyślij zapytanie</button>
        </form>
    </div>
</div>
</div>



<app-inneuslugi></app-inneuslugi>
<app-miasta></app-miasta>
<app-footer></app-footer>
<app-mapa></app-mapa>
